import React, { useContext, useEffect } from "react"


import * as styles from "./PrivacyPolicyContent.module.scss"

export default function PrivacyPolicyContent() {

    return (
        <section className={styles.Wrapper}>
            <div className={styles.HeaderWrapper}>
                <h2>Polityka prywatności</h2>
            </div>
            <article className={styles.Article}>
                <div>
                    <p>
                        Używamy plików cookie, aby zapewnić Ci najlepsze doświadczenia na
                        naszej stronie internetowej. Kontynuując korzystanie z tej strony
                        internetowej, zgadzasz się z naszą Polityką Prywatności.
                    </p>
                </div>

                <div>
                    <h3>1. Administrator strony internetowej</h3>
                    <p>
                        Właścicielem i administratorem strony hexar.tech jest Hexar Sp. z o. o.
                        działająca na podstawie prawa Rzeczypospolitej Polskiej z siedzibą przy ul.
                        Henryka Sienkiewicza 85/87 p.XI lok. 8, 90-057 Łódź i zarejestrowana w
                        Krajowym Rejestrze Sądowym pod numerem KRS: 0000853769, NIP:
                        7252301704, REGON: 386714708.
                    </p>
                </div>

                <div>
                    <h3>2. Dlaczego zbieramy informacje</h3>
                    <p>
                        Wykorzystujemy i gromadzimy informacje o użytkownikach w celu
                        świadczenia usług oraz lepszego zrozumienia, w jaki sposób odwiedzający
                        korzystają z witryny, a także w celu przedstawienia im ważnych informacji.
                    </p>
                </div>

                <div>
                    <h3>3. Jak wykorzystujemy zebrane informacje</h3>
                    <p>
                        Możemy wykorzystywać informacje, które zbieramy od Ciebie podczas
                        rejestracji, dokonywania zakupów, zapisywania się do naszego
                        newslettera, odpowiadania na ankietę lub komunikację marketingową,
                        przeglądania strony internetowej lub korzystania z niektórych innych
                        funkcji strony w następujący sposób:
                    </p>
                    <ul>
                        <li>
                            Aby ulepszyć naszą stronę internetową w celu oferowania lepszych
                            usług dla naszych klientów
                        </li>
                        <li> Aby spersonalizować doświadczenia użytkownika</li>
                        <li>Aby zapewnić obsługę klienta i wsparcie</li>
                        <li>Aby przeprowadzić ankietę</li>
                        <li>Aby przetwarzać płatności</li>
                        <li>Aby kontaktować się z klientami (czat na żywo lub e-mail).</li>
                    </ul>
                </div>

                <div>
                    <h3>4. W jaki sposób zbieramy informacje</h3>
                    <p>
                        Jak każda inna strona internetowa zbieramy informacje o urządzeniach za
                        pomocą następujących technologii:
                    </p>
                    <ul>
                        <li>
                            Log files - śledzą działania zachodzące na stronach i zbierają dane, w tym
                            adres IP odwiedzającego, typ przeglądarki, dostawcę usług internetowych,
                            strony odsyłające/wychodzące oraz znaczniki daty/czasu.
                        </li>
                        <li>
                            Web beacons - pliki elektroniczne używane do rejestrowania informacji o
                            sposobie przeglądania Strony. Sygnały nawigacyjne noszą również nazwę
                            "web bugs" i są zazwyczaj wykorzystywane przez strony internetowe,
                            które korzystają z usług podmiotów trzecich w zakresie monitorowania i
                            śledzenia ruchu.
                        </li>
                        <li>
                            Cookies - małe pliki, które strona internetowa przesyła na dysk twardy
                            Państwa komputera za pośrednictwem przeglądarki internetowej (jeśli
                            Państwo na to pozwalają), które umożliwiają stronie internetowej
                            rozpoznanie Państwa przeglądarki oraz przechwycenie i zapamiętanie
                            określonych informacji osobistych.
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>5. Cookies</h3>
                    <h3>Jak używamy plików cookie?</h3>
                    <p>
                        Możemy używać plików cookie do analizowania ruchu na naszej stronie
                        internetowej za pomocą pakietu analitycznego. Zagregowane dane
                        dotyczące użytkowania pomagają nam w ulepszaniu struktury, wyglądu,
                        zawartości i funkcji Strony. Używamy narzędzi takich jak Hotjar, Google
                        Analytics, Google Tag Manager, Google Ads. Pliki cookie nie dają nam
                        dostępu do Twojego komputera ani żadnych informacji o Tobie, innych niż
                        te, które zdecydujesz się nam udostępnić.
                    </p>
                    <h3>Zarządzanie plikami cookie</h3>
                    <p>
                        Instrukcje dotyczące zarządzania plikami cookie można znaleźć w
                        najpopularniejszych przeglądarkach:
                    </p>
                    <ul>
                        <li>
                            <a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&hl=pl">
                                Google Chrome
                            </a>
                        </li>
                        <li>
                            <a href="https://support.mozilla.org/pl/kb/ciasteczka">
                                Mozilla Firefox
                            </a>
                        </li>
                        <li>
                            <a href="https://help.opera.com/pl/latest/security-and-privacy/">
                                Opera
                            </a>
                        </li>
                        <li>
                            <a href="https://support.apple.com/pl-pl/guide/safari/sfri11471/mac">
                                Safari
                            </a>
                        </li>
                        <li>
                            <a href="https://support.microsoft.com/pl-pl/microsoft-edge/usuwanie-plik%C3%B3w-cookie-w-przegl%C4%85darce-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09">
                                Microsoft Edge
                            </a>
                        </li>
                        <li>
                            <a href="https://support.brave.com/hc/en-us/articles/360050634931-How-Do-I-Manage-Cookies-In-Brave">
                                Brave
                            </a>
                        </li>
                    </ul>
                </div>
                <div>
                    <h3>6. Bezpieczeństwo danych</h3>
                    <p>
                        Stosujemy odpowiednie praktyki gromadzenia, przechowywania i
                        przetwarzania danych oraz środki bezpieczeństwa w celu ochrony przed
                        nieuprawnionym dostępem, zmianą, ujawnieniem lub zniszczeniem danych
                        osobowych, nazwy użytkownika, hasła, informacji o transakcjach i danych
                        przechowywanych na naszej Stronie.
                    </p>
                </div>
                <div>
                    <h3>7. Linki do innych stron internetowych</h3>
                    <p>
                        Nasza Strona może zawierać linki do innych stron internetowych. Należy
                        pamiętać, że nie mamy żadnej kontroli nad stronami internetowymi poza
                        domeną hexar.tech. Dlatego prosimy o zapoznanie się z polityką
                        prywatności tam obowiązującą, ponieważ nie jesteśmy za nią
                        odpowiedzialni.
                    </p>
                </div>
                <div>
                    <h3>8. Zmiany w niniejszej Polityce Prywatności</h3>
                    <p>
                        Możemy wprowadzać zmiany, modyfikacje lub poprawki do niniejszej
                        Polityki Prywatności w dowolnym momencie bez uprzedzenia. Korzystając
                        z tej strony zgadzasz się na przestrzeganie aktualnej wersji Polityki
                        Prywatności.
                    </p>
                </div>
                <div>
                    <h3>9. Kontakt z nami</h3>
                    <p>
                        Jeśli chcesz złożyć skargę lub masz jakiekolwiek pytania, skontaktuj się z
                        nami poprzez formularz kontaktowy lub e-mail hello@hexar.tech
                    </p>
                </div>
            </article>
        </section>
    )
}