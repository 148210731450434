import React from "react"

import Seo from "src/components/seo"
import Layout from "src/components/layout/Layout";
import ContactFooter from "src/components/layout/ContactFooter/ContactFooter";
import PrivacyPolicyContent from "src/components/pages/privacy-policy/PrivacyPolicyContent";
import Patrons from "src/components/layout/Patrons/Patrons";

const index = () => {
    return (
        <Layout
        >
            <Seo />
            <PrivacyPolicyContent />
            <ContactFooter showForm={true} showInfo={true} />
            <Patrons />
        </Layout>
    )
}

export default index;